import React, { useEffect, useState } from "react";
import AppDialog from "../../common/dialog/AppDialog";
import { Box, CircularProgress, makeStyles, MuiThemeProvider, Typography } from "@material-ui/core";
import theme from "../../../theme";
import TextInputV3, { CustomInputLabel } from "components/input/TextInputV3";
import Form, { FormInput } from "components/input/Form";
import PrimaryButton from "components/buttons/PrimaryButton";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import Toast from "light-toast";
import { updateEmailAction, updateEmailStartAction, validateUserEmail } from "actions/userAction";
import { useAppDispatch } from "hooks";
import { setUserData, setUserLocalDbData } from "reducers/user/UserSlice";

const componentStyles = makeStyles(() => ({
  root: {},
  input: {
    marginBottom: 24
  },
  hiddenInput: {
    height: 0
  },
  orderButton: {
    height: "50px",
    borderRadius: "25px",
    marginTop: "4px"
  },
  orderCircularProgress: {
    color: "#fff",
    marginRight: "8px"
  }
}));

interface UpdateEmailDialogProps {
  open: boolean;
  initialEmail: string;
  nickname: string;
  onClose: () => void;
  onEmailUpdated: (newEmail: string) => void;
}

const initialFormInputs = {
  email: FormInput(),
  code: FormInput()
};

const UpdateEmailDialog: React.FC<UpdateEmailDialogProps> = ({
  open,
  initialEmail,
  nickname,
  onClose,
  onEmailUpdated
}: UpdateEmailDialogProps) => {
  const classes = componentStyles();
  const { isMobile } = useMediaBreakpoints();
  const dispatch = useAppDispatch();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState<boolean>(false);

  useEffect(() => {
    formInputs.email.value = initialEmail;
    formInputs.code.value = "";
    setFormInputs({ ...formInputs });
  }, [initialEmail]);

  const validateForm = (inputs = formInputs) => {
    let hasError: boolean = false;

    for (const inputName in inputs) {
      const formInput = inputs[inputName];

      if ("email" === inputName && !emailSent) {
        formInput.error = formInput.value === "" ? "This is required" : "";
        hasError = hasError ? hasError : formInput.error !== "";
      } else if ("code" === inputName && emailSent) {
        formInput.error = formInput.value === "" ? "This is required" : "";
        hasError = hasError ? hasError : formInput.error !== "";
      }
    }

    return !hasError;
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  const sendEmail = async () => {
    if (isSaving || formInputs.email.value === initialEmail || !validateForm()) {
      return;
    }

    setIsSaving(true);

    // Toast.info("Updating Your Account...", 500);

    interface UpdateEmailStartPayloadObject {
      email: string;
    }

    const updateEmailStartPayloadObject: UpdateEmailStartPayloadObject = {
      email: formInputs.email.value
    };

    try {
      // first validate the email
      const validateEmailResponse = await validateUserEmail(formInputs.email.value);
      if (!validateEmailResponse.data.valid) {
        Toast.fail("Email address is invalid", 3000, () => {
          Toast.hide();
        });
        setIsSaving(false);
        setEmailSent(false);
        return;
      }

      const response = await updateEmailStartAction(updateEmailStartPayloadObject);
      setIsSaving(false);
      if (response.data.success) {
        setEmailSent(true);
      } else {
        Toast.fail("An error has occurred. Please contact support", 3000, () => {
          Toast.hide();
        });
        setEmailSent(false);
      }
    } catch (error) {
      setIsSaving(false);
      setEmailSent(false);
    }
  };

  const onSubmit = async () => {
    if (!isSaving && !emailSent) {
      await sendEmail();
      return;
    }

    if (emailSent) {
      await verifyOtp();
    }
  };

  const verifyOtp = async () => {
    if (!validateForm()) {
      return;
    }

    setIsVerifyingOtp(true);

    interface UserPayloadObject {
      nickname: string;
      email: string;
      birthYear?: string;
      birthMonth?: string;
      birthDay?: string;
      firstname?: string;
      lastname?: string;
      address?: string;
      country?: string;
      city?: string;
      state?: string;
      zip?: string;
      gender?: string;
      vanityUrl?: string;
      code: string;
    }

    const payload: UserPayloadObject = {
      nickname: nickname,
      email: formInputs.email.value,
      code: formInputs.code.value
    };

    try {
      const response = await updateEmailAction(payload);
      setIsVerifyingOtp(false);
      if (!response.data.success) {
        Toast.fail(
          response.data.message ? response.data.message : "An error has occurred. Please contact support",
          3000,
          () => {
            Toast.hide();
          }
        );
        return;
      }

      dispatch(setUserLocalDbData(response.data.localDbData));
      dispatch(setUserData(response.data.userData));
      Toast.success("Your Email has been updated!", 2000);
      setIsVerifyingOtp(false);
      onEmailUpdated(formInputs.email.value);
      onModalClosed();
    } catch (error) {
      setIsVerifyingOtp(false);
      Toast.fail(
        error.response?.data?.message ? error.response.data.message : "An error has occurred. Please contact support",
        3000,
        () => {
          Toast.hide();
        }
      );
    }
  };

  const onModalClosed = () => {
    setIsSaving(false);
    setEmailSent(false);
    setIsVerifyingOtp(false);
    onClose();
  };

  return (
    <MuiThemeProvider theme={theme}>
      <AppDialog open={open} onClose={onModalClosed} title="Update Email" maxWidth="sm">
        {!emailSent ? (
          <>
            <CustomInputLabel htmlFor={"email"}>Enter new Email Address</CustomInputLabel>
            <Box display={isMobile ? "block" : "inline-flex"}>
              <TextInputV3
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={formInputs.email.value}
                error={formInputs.email.error}
                helperText={formInputs.email.error}
                onChange={handleInputChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                className={classes.input}
                style={{ width: isMobile ? "100%" : "300px" }}
              />
              <Box style={{ minWidth: "8px" }}></Box>
              <PrimaryButton
                className={classes.orderButton}
                handleClick={onSubmit}
                style={{ width: isMobile ? "100%" : "240px" }}
              >
                {isSaving ? <CircularProgress className={classes.orderCircularProgress} size={20} /> : null}
                {isSaving ? "Updating..." : "Update Email"}
              </PrimaryButton>
            </Box>
          </>
        ) : (
          <>
            <CustomInputLabel htmlFor={"email"}>Enter Code</CustomInputLabel>
            <Typography variant="body2" color="textSecondary">
              Enter the code sent to {formInputs.email.value}
            </Typography>
            <TextInputV3
              id="email"
              name="email"
              type="email"
              hidden={true}
              value={formInputs.email.value}
              error={formInputs.email.error}
              helperText={formInputs.email.error}
              onChange={handleInputChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              className={classes.hiddenInput}
              style={{ visibility: "hidden" }}
            />
            <Box display={isMobile ? "block" : "inline-flex"}>
              <TextInputV3
                id="code"
                name="code"
                value={formInputs.code.value}
                error={formInputs.code.error}
                helperText={formInputs.code.error}
                onChange={handleInputChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                className={classes.input}
                style={{ width: isMobile ? "100%" : "300px" }}
              />
              <Box style={{ minWidth: "8px" }}></Box>
              <PrimaryButton
                className={classes.orderButton}
                handleClick={onSubmit}
                style={{ width: isMobile ? "100%" : "240px" }}
              >
                {isVerifyingOtp ? <CircularProgress className={classes.orderCircularProgress} size={20} /> : null}
                {isVerifyingOtp ? "Verifying..." : "Verify"}
              </PrimaryButton>
            </Box>
          </>
        )}
      </AppDialog>
    </MuiThemeProvider>
  );
};

export default UpdateEmailDialog;
